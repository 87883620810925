import { PropsWithChildren, useCallback, useEffect } from "react"
import ApplicationLogo from "@/components/ApplicationLogo"
import { Link, router, usePage } from "@inertiajs/react"
import { createTheme, ThemeProvider } from "@mui/material"
import HeaderMenu from "@/components/HeaderMenu"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { getUserInvites, getUserSessions } from "@/services/UserService"
import {
    getGroups,
    postGroupAcceptInvite,
    postGroupRevokeInvite,
} from "@/services/GroupService"
import HeaderInvites from "@/components/HeaderInvites"
import {
    postSessionAcceptInvite,
    postSessionRevokeInvite,
} from "@/services/SessionService"
import { useBonzaContext } from "@/context/BonzaContext"
import { PageProps } from "@/types"
import HeaderMiddle from "@/components/HeaderMiddle"
import NavBar from "@/components/NavBar"
import Alert from "@/components/icons/alert"
import Profile from "@/components/icons/profile"

export enum ActiveIcon {
    Home,
    MusicNote,
    Group,
    Person,
    GraphicEq,
    Power,
    Download = 6,
}

export const theme = createTheme({
    palette: {
        primary: {
            main: "#525AE9",
        },
        secondary: {
            main: "rgba(255, 255, 255, 0.7)",
        },
    },
})

export default function Authenticated({ children }: PropsWithChildren) {
    const {
        auth: { user },
        connection,
    } = usePage<PageProps>().props
    const queryClient = useQueryClient()

    const { setUser, updateConnection } = useBonzaContext()

    const { data: invites, refetch: refetchInvites } = useQuery({
        queryKey: ["invites"],
        queryFn: getUserInvites,
    })

    const { mutate: acceptGroupInvite } = useMutation({
        mutationKey: ["acceptInvite"],
        mutationFn: async (id: number) => {
            const { group: { id: group_id } } = await postGroupAcceptInvite(id)
            await queryClient.invalidateQueries({ queryKey: ["groupMembers"] })
            await queryClient.invalidateQueries({ queryKey: ["invites"] })
            const debRoute = route("group.profile", { id: group_id })
            router.get(debRoute)
        },
    })

    const { mutate: revokeGroupInvite } = useMutation({
        mutationKey: ["revokeInvite"],
        mutationFn: async (id: number) => {
            await postGroupRevokeInvite(id)
            queryClient.invalidateQueries({ queryKey: ["invites"] })
        },
    })

    const { mutate: acceptSessionInvite } = useMutation({
        mutationKey: ["acceptInvite"],
        mutationFn: async (id: number) => {
            const { bonza_session: { id: bonza_session_id } } = await postSessionAcceptInvite(id)
            await queryClient.invalidateQueries({
                queryKey: ["sessionMembers"],
            })
            await queryClient.invalidateQueries({ queryKey: ["invites"] })
            const debRoute = route("session.profile", { id: bonza_session_id })
            router.get(debRoute)
        },
    })

    const { mutate: revokeSessionInvite } = useMutation({
        mutationKey: ["revokeInvite"],
        mutationFn: async (id: number) => {
            await postSessionRevokeInvite(id)
            queryClient.invalidateQueries({ queryKey: ["invites"] })
        },
    })

    const groupInviteCallback = useCallback((id: number, accept: boolean) => {
        if (accept) acceptGroupInvite(id)
        else revokeGroupInvite(id)
        refetchInvites()
    }, [])

    const sessionInviteCallback = useCallback((id: number, accept: boolean) => {
        if (accept) acceptSessionInvite(id)
        else revokeSessionInvite(id)
        refetchInvites()
    }, [])

    useEffect(() => {
        setUser(user)
    }, [])

    useEffect(() => {
        if (connection) updateConnection(connection)
    }, [user])

    return (
        <ThemeProvider theme={theme}>
            <div
                className={`
                    mx-auto flex min-h-screen max-w-[1920px] flex-col
                    justify-center space-y-6 bg-bonza-dark
                `}
            >
                <header
                    className={`
                        flex h-16 w-full flex-row items-center justify-between
                        gap-12 px-6 pt-6
                    `}
                >
                    <Link href={route("dashboard")}>
                        <ApplicationLogo
                            className={`float-left h-9 w-auto fill-current`}
                        />
                    </Link>

                    <HeaderMiddle />

                    <div
                        className={`
                            flex flex-row items-center justify-end space-x-4
                        `}
                    >
                        <HeaderInvites
                            invites={invites}
                            groupCallback={groupInviteCallback}
                            sessionCallback={sessionInviteCallback}
                            icon={<Alert className="h-6 w-6" />}
                        />
                        {/*<Link
                            href="#"
                            className={`
                                relative rounded-full bg-bonza-dark-semi p-3
                                ring-0 transition duration-100 ease-in-out

                                hover:ring hover:ring-bonza-primary
                            `}
                        >
                            <img src="/images/icons/message.svg" />
                        </Link>*/}
                        <HeaderMenu
                            icon={<Profile className="h-6 w-6" />}
                            options={[
                                {
                                    name: "Profile",
                                    href: route("profile.edit"),
                                },
                                {
                                    name: "Log out",
                                    href: route("logout"),
                                    onClick: () => queryClient.clear(),
                                    method: "post",
                                    as: "button",
                                },
                            ]}
                        />
                    </div>
                </header>

                <main
                    className={`
                        flex grow flex-row items-stretch justify-start
                        transition-all
                    `}
                >
                    <NavBar />
                    <div className={`flex w-full grow overflow-auto`}>
                        {children}
                    </div>
                </main>
            </div>
        </ThemeProvider>
    )
}
